import React from 'react'
import SEO from "../../../components/atoms/seo"

import Layout from '../../../components/organisms/layout'
import Heading from '../../../components/molecules/heading'
import Title  from '../../../components/atoms/title'
import Loader from '../../../components/molecules/loader'

const StyleguideComponentsLoaderPage = () => (
  <Layout>
    <SEO title='Styleguide' />
    <Heading 
        title='Styleguide - Components - Loader'
        weight='light'
    />
    <div className='p-styleguide l-spacing__m-b--40'>
      <Title
        type='h5'
        title='Regular'
        align='center'
        color='dark'
        weight='bold'
        classNames='l-spacing__m-tb--40'
      />
      <Loader 
        title='Saving your answer...' 
        onComplete={() => console.log('done')} 
        isLinear 
      />
      <Title
        type='h5'
        title='Circular'
        align='center'
        color='dark'
        weight='bold'
        classNames='l-spacing__m-tb--40'
      />
      <Loader 
        title='Processing your information...' 
        isCircular 
      />
      <Title
        type='h5'
        title='Regular with TrustPilot'
        align='center'
        color='dark'
        weight='bold'
        classNames='l-spacing__m-tb--40'
      />
      <Loader 
        trustContent={{
          "quote": "This program had my trust from day one. The people are knowledgeable, honest, trustworthy and made the progress simple as pie.",
          "name": "Eccleston"
        }}
        onComplete={() => console.log('done')} 
        isLinear
        isTrust
      />
      <Title
        type='h5'
        title='Mui'
        align='center'
        color='dark'
        weight='bold'
        classNames='l-spacing__m-tb--40'
      />
      <Loader 
        title='Saving your answer...' 
        onComplete={() => console.log('done - regular')} 
        isMui
      />
      <Title
        type='h5'
        title='Mui with TrustPilot'
        align='center'
        color='dark'
        weight='bold'
        classNames='l-spacing__m-tb--40'
      />
      <Loader 
        trustContent={{
          "quote": "This program had my trust from day one. The people are knowledgeable, honest, trustworthy and made the progress simple as pie.",
          "name": "Eccleston"
        }}
        onComplete={() => console.log('done - regular with trustpilot')} 
        isMui
        isTrust
      />
    </div>
  </Layout>
)

export default StyleguideComponentsLoaderPage
